<template>
  <v-list flat>
    <v-list-item-group
      color="primary">
      <v-list-item
        v-for="(item) in items"
        :key="item.id"
        @click="$emit('addToSelected', item)">
        <v-list-item-content
          :id="item.id"
          v-intersect.once="onIntersect"
          class="pa-0">
          <v-row
            style="width: 100%"
            no-gutters
            justify="space-between"
            align="center">
            <v-col
              cols="12"
              md="10">
              <v-list-item-title>
                <ProductType
                  :types="item.productType || []"
                  orientation="row" />
              </v-list-item-title>
              <v-row
                no-gutters>
                <v-col
                  v-for="mapItem in renderingMapping"
                  :key="mapItem.value"
                  md="4"
                  cols="12">
                  <p class="small-p text-uppercase lightGrey--text">
                    {{ mapItem.title }}
                  </p>
                  <p class="small-p">
                    {{
                      item[mapItem.value]
                    }}
                  </p>
                  <p />
                </v-col>
              </v-row>
            </v-col>
            <ProductThumbnail
              :items="items"
              :item="item"
              :attachment="item.attachment" />
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  name: 'ProductCellFieldProductAutocompleteResults',
  components: {
    ProductThumbnail: () => import('@/components/Product/ProductThumbnail'),
    ProductType: () => import('@/components/App/AppListingElements/AppListingBlockElement/BlockElement/ElementProductType'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      renderingMapping: [
        {
          title: 'Collection', value: 'collectionName',
        },
        {
          title: 'Manufacturer', value: 'manufacturer',
        },
        {
          title: 'Model', value: 'model',
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      this.$emit('onIntersect', {
        entries,
        isIntersecting,
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .v-list {
    height: calc(100vh - 250px);
    overflow: auto;
    padding-top: 24px;
    &-item {
      margin-top: 16px !important;
      min-height: 40px !important;
      &:hover {
        opacity: 0.7 !important;
      }
      &--active::before {
        opacity: 0.12;
      }
      &__title {
        margin-bottom: 8px;
        font-size: 16px !important;
      }
      height: auto;
      p {
        margin: 0;
      }
    }
  }
  .v-list-item__content {
    overflow: visible;
  }
</style>
